<template>
  <div class="root flex_center">
    <div class="swiper_con relative">
      <div class="title_box flex_between_align_center">
        <Title :titleName="titleName"></Title>
      </div>
      <!-- tab栏 -->
      <div class="menu_box flex_center">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="硕士" name="1" :lazy="true">
            <div
              class="flex_between_align_center relative"
              v-if="activeName === '1'"
            >
              <div class="chart" id="chart" ref="chart" v-if="chartShow"></div>
              <div class="hide_chart_logo"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="博士" name="2" :lazy="true">
            <div
              class="flex_between_align_center relative"
              v-if="activeName === '2'"
            >
              <div class="chart" id="chart" ref="chart"></div>
              <div class="hide_chart_logo"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="本科" name="3" :lazy="true">
            <div
              class="flex_between_align_center relative"
              v-if="activeName === '3'"
            >
              <div class="chart" id="chart" ref="chart"></div>
              <div class="hide_chart_logo"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getMajor } from "@/api/home.js";
import commonMeth from "../../commonMeth/index";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "海师专业概览",
        english: "MAJOR",
      },
      activeName: "1",
      menuList: [{ label: "硕士" }, { label: "博士" }, { label: "本科" }],
      chartColor: [
        "#FD9738",
        "#FDA143",
        "#FEB455",
        "#FEAB4C",
      ],
      allData: [],
      chartData: [
        {
          name: "理科1",
          count: 120,
          list: [
            {
              name: "Sales inquiries",
              count_item: 60,
            },
            {
              name: "Support requests",
              count_item: 35,
            },
          ],
        },
      ],
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "major" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // 获取数据
    getMajor().then((res) => {
      if (res.code === 200) {
        this.allData = res.data;
        this.chartData = res.data.bs_hwds;
        this.initChart();
      }
    });
  },
  methods: {
    // 初始化图表
    initChart() {
      am4core.useTheme(am4themes_animated);
      this.chartData.forEach((item, index) => {
        item.color = am4core.color(this.chartColor[index]);
      });
      let data = this.chartData;
      //Chart container
      let chart = am4core.create(this.$refs.chart, am4core.Container);
      chart.width = am4core.percent(100);
      chart.height = am4core.percent(100);
      chart.layout = "horizontal";
      // 创建饼图
      let pieChart = chart.createChild(am4charts.PieChart);
      pieChart.width = am4core.percent(50);
      // pieChart.height = 50;
      pieChart.data = data;
      pieChart.innerRadius = am4core.percent(50);
      let pieSeries = pieChart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "count";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.propertyFields.fill = "color";

      pieSeries.labels.template.disabled = true;
      // 模块分割线
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 3;
      pieSeries.slices.template.strokeOpacity = 1;
      // 鼠标移入展示文字
      pieSeries.slices.template.tooltipText =
        "[#fff]{category}: {value.percent.formatNumber('#.#')}%  ({value}人)";
      // 动画效果
      pieSeries.hiddenState.properties.endAngle = -90;
      //Set up labels
      let label1 = pieChart.seriesContainer.createChild(am4core.Label);
      label1.text = "";
      label1.horizontalCenter = "middle";
      label1.fontSize = 18;
      label1.fontWeight = 400;
      label1.dy = -20;
      let label2 = pieChart.seriesContainer.createChild(am4core.Label);
      label2.text = "";
      label2.horizontalCenter = "middle";
      label2.fontSize = 26;
      label2.fontWeight = 600;
      label2.dy = 10;
      // 默认激活
      pieChart.events.on("ready", function (ev) {
        pieSeries.slices.getIndex(0).isActive = true;
      });
      
      // 创建XY柱状图
      let columnChart = chart.createChild(am4charts.XYChart);
      columnChart.width = am4core.percent(40);
      // x轴
      let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.labels.template.fill = am4core.color("#666");
      categoryAxis.renderer.labels.template.fontSize = 15;
      categoryAxis.dataFields.category = "name";
      categoryAxis.dataFields.value = "count_item";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      // 去掉横向网格线
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.grid.width = 10;
      // 取消数据过多x轴显示不全
      categoryAxis.renderer.minGridDistance = 20;
      //  y轴
      let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());
      // 去掉纵向网格线
      valueAxis.renderer.grid.template.disabled = false;
      // 网格线颜色
      valueAxis.renderer.grid.template.strokeOpacity = 0.1;
      valueAxis.renderer.grid.template.strokeDasharray = "3,3"; //创建长度为3个像素的虚线，它们之间的间隔为3个像素”
      valueAxis.renderer.labels.template.fill = am4core.color("#999");
      valueAxis.renderer.labels.template.fontSize = 14;
      //设置最小值和最大值区间
      valueAxis.adjustLabelPrecision = false;
      // valueAxis.min = 0;
      // valueAxis.max = 5; 
      //格式化数字显示
      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#"; 
      // 禁用y轴
      // valueAxis.renderer.labels.template.disabled = false;
      // 柱状图系列 series
      let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.categoryY = "name";
      columnSeries.dataFields.valueX = "count_item";
      columnSeries.columns.template.strokeWidth = 0;
      columnSeries.columns.template.events.on("hit", function (event, a) {
        let val = "haishi";
        vm.openNewTab({
          name: val,
          query: { major: event.target.dataItem.categories.categoryY },
        });
        // console.log("eve", event);
        // vm.$router.push(`/haishi?major=${event.target.dataItem.categories.categoryY}`);
      });
      var bullet = columnSeries.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = "{valueY}";
      bullet.label.verticalCenter = "bottom";

      // let bullet = columnSeries.bullets.push(new am4charts.LabelBullet());
      // bullet.interactionsEnabled = false;
      // bullet.dy = 0;
      // bullet.dx = 20;
      // bullet.label.text = "{value}";
      // bullet.label.fill = am4core.color("#f00");
      // columnChart.maskBullets = false;
      columnSeries.columns.template.color = am4core.color("#104547");
      // let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
      // 切换事件
      pieSeries.slices.template.events.on("toggled", function (ev) {
        if (ev.target.isActive) {
          // Untoggle other slices
          pieSeries.slices.each(function (slice) {
            if (slice != ev.target) {
              slice.isActive = false;
            }
          });
          // Update column chart
          columnSeries.appeared = false;
          columnChart.data = ev.target.dataItem.dataContext.list;
          columnSeries.fill = ev.target.fill;
          columnSeries.reinit();
          label1.text = ev.target.dataItem.category;
          label1.fill = "#666666";

          // Update labels
          label2.text = ev.target.dataItem.value + "人";
          // label2.text = pieChart.numberFormatter.format(
          //   ev.target.dataItem.values.value.percent,
          //   "#.'%'"
          // );
          // 修改文字颜色
          // label2.fill = ev.target.fill;
        }
      });
    },
    // tab点击
    tabClick(tab, event) {
      this.$nextTick(() => {
        if (this.activeName === "1") this.chartData = this.allData.bs_hwds;
        else if(this.activeName === "2") this.chartData = this.allData.ss_hwds;
        else this.chartData = this.allData.bk_hwds
        this.initChart();
      });
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },

    // 新tab
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      window.open(routeData.href, "_blank");
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url(./image/swiperTwoBg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative {
  position: relative;
}
.swiper_con {
  width: 1200px;
  height: 850px;
}
.menu_box {
  margin-top: 46px;
}
// el-tab样式
/deep/ .el-tabs__item.is-active {
  color:#FD9738;
  font-size: 18px;
  font-weight: 600;
}
/deep/ :root {
  --swiper-theme-color: #d99d66;
}
/deep/ .el-tabs__nav-wrap::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: none;
  z-index: 1;
}
/deep/ .el-tabs__active-bar {
  height: 3px;
  background-color: #FD9738;
  border-radius: 8px;
}
/deep/ .el-tabs__item:hover,
/deep/ .el-tabs__item {
  color: #666666;
}
/deep/ .el-tab-pane {
  margin-right: 15px;
  padding: 82px 0 20px 0;
}

/deep/ .el-tabs__header {
  position: relative;
  .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 2px solid #EDEEF5;
    position: relative;
    .el-tabs__nav{
      position: relative;
      bottom: -2px;
    }
  }
}
/deep/ .el-tabs__item {
  width: 120px;
  text-align: center;
  padding: 0;
  padding-bottom: 50px;
}
// /deep/ [data-v-d9970872] .el-tabs__active-bar {
//   width: 100px !important;
//   bottom: -20px;
// }
/deep/ .el-tabs__nav-scroll {
  overflow: visible;
}
/deep/ .el-tabs__nav-wrap {
  overflow: visible;
}

// tooltip
.tooltip_box {
  padding: 10px;
  color: #fff;
  font-size: 16px;
  background: #f3bc70;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border: 2px solid #ffffff;
  display: inline-block;
  border-radius: 8px;
}
// 图表容器
.chart {
  width: 1200px;
  height: 500px;
  margin-right: 80px;
}
// 隐藏amcharts logo
.hide_chart_logo {
  width: 70px;
  height: 25px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
