<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
      </div>
      <!-- <div class="tooltip">
        <div class="title_box flex_align">
          <div class="tooltip_text">
            外籍教师
          </div>
        </div>
        <div class="tooltipChart" id="tooltipChart" style="width:505px;height:294px;"></div>
      </div> -->
      <!-- 图表和文字展示 -->
      <div class="con flex_center">
        <div id="identity_chart" v-if="chartShow"></div>
        <ul class="text">
          <li>69.89%的大学生认为是可以帮助学生开固眼界</li>
          <li>62.19%的受访大学生表示是引导学生理论与实践相结合</li>
          <li>58.29%的受访大学生表示老师是他们步入社会的领路人</li>
          <li>
            35.49%的受访大学生认为能引导学生向学科领域深耕细作，提高研究能力
          </li>
        </ul>
      </div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import { getIdentity } from "@/api/home.js";
import commonMeth from "../../commonMeth/index";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "海师身份画像分析",
        english: "IDENTITY",
      },
      // tooltip中图表配置
      twoOption: {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          position: function (point, params, dom, rect, size) {
            return [point[0] + 8, point[1] + 8];
          },
        },
        legend: {
          icon: "rect",
          itemHeight: 12,
          itemWidth: 12,
          orient: "vertical",
          x: "79%",
          y: "center",
          itemGap: 20,
          textStyle: {
            color: "#62472c",
          },
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            center: ["35%", "53%"],
            data: [
              // { value: 1048, name: "名校毕业" },
            ],
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#f3bc70",
                    "#f4c079",
                    "#f4c380",
                    "#f4c788",
                    "#f5cc93",
                    "#f6d09c",
                    "#f6d5a7",
                  ];
                  return colorList[params.dataIndex];
                },
                labelLine: {
                  lineStyle: {
                    color: "#ededed",
                  },
                },
                label: {
                  textStyle: {
                    color: "#666",
                  },
                },
                lineStyle: {
                  color: "red",
                },
              },
            },
          },
          {
            type: "pie",
            radius: "70%", //饼图大小
            center: ["35%", "53%"], //饼图位置
            data: [],
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: "#fff",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}%",
                  color: "#fff",
                },
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#f3bc70",
                    "#f4c079",
                    "#f4c380",
                    "#f4c788",
                    "#f5cc93",
                    "#f6d09c",
                    "#f6d5a7",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
      nameData: [], //雷达图indicator数据
      valueData: [], //雷达图值
      allData: [], //所有数据集合
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "identity" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // tooltip中饼图跳转
    window.toPathIdentity = (val,hssf)=> {
      this.openNewTab({name:val})
    };
    // 获取数据
    getIdentity().then((res) => {
      if (res.code === 200) {
        let resData = res.data;
        this.allData = resData;
        resData.forEach((item) => {
          this.nameData.push({ name: `${item.title}`, max: 100 });
          this.valueData.push(item.list.total_percent);
          this.nameData = [
            { name: "职场精英", max: 60 },
            { name: "外籍教师", max: 60 },
            { name: "海外服务顾问" , max: 60},
            { name: "名校导师", max: 60 },
            { name: "招生官" , max: 60},
            { name: "超级学长" , max: 60},
          ];
          this.valueData = [45, 28, 31, 37, 14, 55.3];
          // this.valueData = [33.5, 8.6, 3.1, 1.7, 1.4, 55.3];
        });
        this.initChart();
      }
    });
  },
  methods: {
    // 渲染图表
    initChart2() {
      var that = this;
      var myChart = echarts.init(document.getElementById("identity_chart"));
      var option = {
        title: {
          text: "基础雷达图",
        },
        tooltip:{

        },
        legend: {
          data: ["预算分配（Allocated Budget）", "实际开销（Actual Spending）"],
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "职场精英" },
            { name: "外籍教师" },
            { name: "海外服务顾问" },
            { name: "名校导师" },
            { name: "招生官" },
            { name: "超级学长" },
          ],
        },
        series: [
          {
            name: "预算 vs 开销（Budget vs spending）",
            type: "radar",
            data: [
              {
                value:[33.5, 8.6, 3.1, 1.7, 1.4, 55.3],
              }

            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart() {
      var that = this;
      var myChart = echarts.init(document.getElementById("identity_chart"));
      const source = {
        data: this.valueData,
        indicator: this.nameData,
      };
      const buildSeries = function (data) {
        const helper = data.map((item, index) => {
          const arr = new Array(data.length);
          arr.splice(index, 1, item);
          return arr;
        });
        return [data, ...helper].map((item, index) => {
          return {
            type: "radar",
            radius: "20%",
            // color: "rgba(232, 179, 107,0.6) ",
            symbol: index === 0 ? "circle" : "none",
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: "#f3bc70", //中心线颜色
              },
              emphasis: {
                borderColor: "#f3bc70", //图形的描边颜色
                borderWidth: 3, // 描边的线宽
              },
            },
            tooltip: {
              padding: 0,
              borderRadius: "20",
              borderColor: "#fff", //tooltip边框颜色
              show: true,
              triggerOn: "click",
              trigger: "item",
              formatter: function (params, ticket, callback) {
                // if (index !== 0) {
                if (params.componentIndex !== 0) {
                  // 当前选中名称
                  let curName = vm.allData[params.componentIndex - 1].title;
                  // 动态获取tooltip中饼图数据
                  let title = source.indicator[index - 1].name;
                  let chartData = vm.transformKey(
                    vm.allData[index - 1].list.list_item
                  );
                  vm.twoOption.series[0].data = chartData;
                  vm.twoOption.series[1].data = chartData;
                  var html = `<div class="tooltip" style="padding:38px;">
              <div class="title_box flex_align">
                <div class="tooltip_text" style="font-size:18px;font-weight:bold;color:#666;line-height:30px">
                  ${title}
                </div>
              </div>
              <div class="tooltipChart" onclick="toPathIdentity('haishi','${curName}')" id="tooltipChart" style="width:505px;height:294px;"></div>
            </div>`;
                  // var html='<div>22</div>'
                  callback(that.setTooltipEchart());
                  return html;
                } else {
                  return "";
                }
              },
            },
            z: index === 0 ? 1 : 2,
            data: [
              {
                value: [...item],
                areaStyle: {
                  opacity: 0.9,
                  color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                    {
                      color: "rgba(253, 151, 55,0.8) ", //区域颜色
                      offset: 0,
                    },
                    {
                      color: "rgba(253, 151, 55,0.8)", //区域颜色
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          };
        });
      };
      var option = {
        tooltip: {
          triggerOn: "click",
          trigger: "item",
        },
        radar: {
          name: {
            textStyle: {
              color: "#666",
              fontSize: 16,
            },
            formatter: "{value}",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(232, 179, 107,0.8) ", // 中心的五条分割线
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(232, 179, 107,0.4)", //圆线 环绕着一圈一圈的线颜色
              type: "dashed",
            },
          },
          splitArea: {
            areaStyle: {
              color: ["none", "none", "none", "none", "none"], //圆环颜色
              shadowColor: "red", // 圆颜色
              shadowBlur: 10,
            },
          },
          indicator: source.indicator,
          radius: 200, //缩放比，解决雷达图文字超出容器显示不全问题
        },
        series: buildSeries(source.data),
      };

      myChart.setOption(option);
    },
    //tooltip中饼图渲染
    setTooltipEchart() {
      const that = this;
      that.$nextTick(() => {
        that.tooltipEchart = echarts.init(
          document.getElementById("tooltipChart")
        );
        that.tooltipEchart.setOption(that.twoOption);
      });
    },
    // 转换obj中key值
    transformKey(array) {
      var keyMap = {
        part_percent: "value",
        title: "name",
      };
      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      return array;
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    // 新tab
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      console.log('跳转',routeData);

      window.open(routeData.href, "_blank");
    },
  },
  computed: {},
};
</script>
<style lang='scss' scoped>
// 共用
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// 图表和文字展示
.con {
  margin-top: 27px;
}
#identity_chart {
  width: 563px;
  height: 629px;
}
.swiper_con {
  width: 1200px;
  height: 850px;
  position: relative;
}
.text {
  width: 334px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  margin-left: 180px;
}

.text li {
  position: relative;
  margin-bottom: 35px;
  letter-spacing: 1px;
}
.text li:before {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6df";
  color: #dda776;
  font-size: 8px;
  position: absolute;
  top: 3px;
  left: -30px;
}

// 图表中tooltip样式
.tooltip {
  width: 565px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.tooltip .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tooltip_text {
  font-size: 18px;
  font-weight: bold;
  color: #666666;
  line-height: 30px;
}

</style>
